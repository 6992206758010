import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr'
import English from 'flatpickr/dist/l10n/default.js'
import { German } from 'flatpickr/dist/l10n/de.js'
import { French } from 'flatpickr/dist/l10n/fr.js'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import { Spanish } from 'flatpickr/dist/l10n/es.js'
import { Dutch } from 'flatpickr/dist/l10n/nl.js'

export default class extends Controller {
  static targets = ['input']
  static values = {
    inline: Boolean,
    static: Boolean,
    locale: String,
    format: String,
  }

  connect() {
    this.fp = flatpickr(this.inputTarget, {
      altInput: true,
      altFormat: this.formatValue || 'D, M d, Y',
      allowInput: true, // Workaround to support HTML5 validation
      inline: this.inlineValue,
      minDate: this.inputTarget.min,
      maxDate: this.inputTarget.max,
      positionElement: this.element,
      static: this.staticValue,
      locale: this.getLocale(),
    })

    // Prevents the user from typing in the input field due to allowInput: true
    this.fp._input.onkeydown = (e) => {
      if (e.key === 'Tab') {
        return true
      }

      return false
    }
  }

  disconnect() {
    const value = this.inputTarget.value
    this.fp.destroy()
    this.inputTarget.value = value
  }

  clear() {
    this.fp.clear()
  }

  getLocale() {
    switch (this.localeValue) {
      case 'de':
        return German
      case 'fr':
        return French
      case 'pt':
        return Portuguese
      case 'es':
        return Spanish
      case 'nl':
        return Dutch
      default:
        return English
    }
  }
}
