import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    roomCommentsFramePath: String,
    blockCommentsFramePath: String,
  }

  connect() {
    if (this.#notInIFrame() && document.location.hash) {
      const anchor = document.location.hash.slice(1)
      const block = document.querySelector(`[id="rooms_block_${anchor}"]`)
      if (block) {
        block.appendChild(this.animationTarget())

        this.addDataAttributeValue(block, 'controller', 'animations--pulse')
        // Stop the pulse animation only 2s after hovering the block
        // to avoid the animation being interrupted immediately when
        // a mouseover is fired because the act of scrolling to the block
        // triggers a mouseover event (because of the position of the mouse
        // at the moment of the page being loaded).
        this.addDataAttributeValue(block, 'animations--pulse-delay-param', 2000)
        this.addDataAttributeValue(
          block,
          'action',
          'mouseover->animations--pulse#stopIn'
        )

        block.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    } else if (this.#isLinkingToComment()) {
      // We can't run this immediately because the drawer
      // controller might not have yet connected
      setTimeout(() => {
        this.#handleLinkToComment()
      }, 100)
    }
  }

  animationTarget() {
    const div = document.createElement('div')
    div.setAttribute('data-animations--pulse-target', 'item')
    div.classList.add(
      'ring-4',
      'ring-offset-1',
      'ring-neutral-600',
      'rounded-3xl',
      'absolute',
      'inset-0',
      'opacity-0',
      'pointer-events-none'
    )
    return div
  }

  addDataAttributeValue(element, attribute, value) {
    const current_value = element.dataset[attribute] || ''
    element.setAttribute(`data-${attribute}`, current_value + ' ' + value)
  }

  #handleLinkToComment() {
    if (this.#commentId().startsWith('block')) {
      const [_level, block_id, id] = this.#commentId().split('_')

      this.dispatch('ui--drawer:right:open', {
        prefix: false,
        detail: {
          framePath: this.blockCommentsFramePathValue
            .replace('block_id_value', block_id)
            .replace('comment_id_value', `rooms_comment_${id}`),
        },
      })
    } else if (this.#commentId().startsWith('room')) {
      const [_level, id] = this.#commentId().split('_')

      this.dispatch('ui--drawer:right:open', {
        prefix: false,
        detail: {
          framePath: this.roomCommentsFramePathValue.replace(
            'comment_id_value',
            `rooms_comment_${id}`
          ),
        },
      })
    }
  }

  #isLinkingToComment() {
    return this.#commentId()
  }

  #commentId() {
    const params = new URLSearchParams(window.location.search)
    return params.get('comment_id')
  }

  #notInIFrame() {
    return window.self === window.top
  }

  get requestParams() {
    return new URLSearchParams(window.location.search)
  }
}
