import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    if (this.#isLinkingToThisGlobalDrawer()) {
      setTimeout(() => this.#handleLinkToThisGlobalDrawer(), 100)
    }
  }

  #handleLinkToThisGlobalDrawer() {
    const drawerName = `ui--drawer:${this.#globalDrawerSide()}:open`
    this.dispatch(drawerName, {
      prefix: false,
      detail: { framePath: this.#globalDrawerPath() },
    })
  }

  #isLinkingToThisGlobalDrawer() {
    return this.#globalDrawerId() && this.#globalDrawerId() === this.element.id
  }

  #globalDrawerId() {
    const params = new URLSearchParams(window.location.search)
    return params.get('global_drawer_id')
  }

  #globalDrawerSide() {
    return this.requestParams.get('global_drawer_id').split('-')[0]
  }

  #globalDrawerPath() {
    return this.requestParams.get('global_drawer_path')
  }

  get requestParams() {
    return new URLSearchParams(window.location.search)
  }
}
