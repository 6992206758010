import { Controller } from '@hotwired/stimulus'
import { enter, leave } from 'el-transition'
import hotkeys from 'hotkeys-js'

export default class extends Controller {
  static values = { identifier: String }
  static targets = [
    'container',
    'backdrop',
    'panel',
    'frame',
    'headerTitle',
    'body',
    'loadingHeaderTitle',
    'loadingBody',
  ]

  async connect() {
    const params = new URLSearchParams(window.location.search)
    const commentId = params.get('comment_id')
    const menu = params.get('menu')

    if (
      commentId &&
      this.hasFrameTarget &&
      this.identifierValue == 'comments'
    ) {
      this.open()
    }

    if (menu && this.identifierValue == menu) {
      this.open()
    }
  }

  open(event) {
    if (
      event &&
      event.detail &&
      event.detail.framePath &&
      this.hasFrameTarget
    ) {
      this.frameTarget.src = event.detail.framePath

      if (this.hasHeaderTitleTarget && this.hasBodyTarget) {
        if (this.hasLoadingHeaderTitleTarget && this.hasLoadingBodyTarget) {
          const temporaryHeader =
            this.loadingHeaderTitleTarget.content.cloneNode(true)
          const temporaryBody = this.loadingBodyTarget.content.cloneNode(true)
          this.headerTitleTarget.replaceChildren(temporaryHeader)
          this.bodyTarget.replaceChildren(temporaryBody)
        } else {
          this.bodyTarget.classList.add('pointer-events-none', 'opacity-15')
        }
      }
    }

    this.addKeyboardShortcuts()

    this.containerTarget.ariaHidden = false
    this.containerTarget.classList.remove('hidden')
    enter(this.backdropTarget)
    enter(this.panelTarget)
  }

  close(_event) {
    Promise.all([leave(this.backdropTarget), leave(this.panelTarget)]).then(
      () => {
        this.containerTarget.classList.add('hidden')
        this.containerTarget.ariaHidden = true
      }
    )

    this.removeKeyboardShortcuts()
  }

  clickOutside({ target }) {
    if (
      this.panelTarget.contains(target) ||
      this.panelTarget.contains(document.activeElement)
    ) {
      return
    }

    this.close()
  }

  disconnect() {
    this.removeKeyboardShortcuts()
  }

  addKeyboardShortcuts() {
    hotkeys('esc', () => this.close())
  }

  removeKeyboardShortcuts() {
    hotkeys.unbind('esc')
  }
}
