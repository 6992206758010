import { Controller } from '@hotwired/stimulus'
import { enter, leave } from 'el-transition'

export default class extends Controller {
  static targets = ['form', 'input', 'display', 'backdrop']

  connect() {
    this.originalValue = this.inputTarget.value
    this.inputTarget.setAttribute('translate', 'no')
    this.displayTarget.setAttribute('translate', 'no')
  }

  startEditing() {
    this.displayTarget.setAttribute('contenteditable', 'true')
    this.displayTarget.focus()
    enter(this.backdropTarget)
  }

  trapClick(e) {
    e.stopPropagation()
  }

  stopEditing() {
    this.displayTarget.removeAttribute('contenteditable')
    this.save()
    leave(this.backdropTarget)
  }

  keydown(event) {
    if (event.keyCode == 13) {
      event.preventDefault()
      this.displayTarget.removeAttribute('contenteditable')
      this.displayTarget.blur()
    }
  }

  save() {
    this.inputTarget.value = this.displayTarget.textContent.trim()

    if (
      this.inputTarget.value != this.originalValue &&
      this.inputTarget.checkValidity()
    ) {
      this.formTarget.requestSubmit()
    }

    if (this.inputTarget.value.length < 1) {
      this.displayTarget.textContent = this.originalValue
    }
  }
}
