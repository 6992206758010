import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['hubspotSection']

  onRowRemoved(_event) {
    this.element.querySelectorAll('input,select').forEach((input) => {
      input.removeAttribute('required')
    })

    this.element.classList.add('hidden')
  }

  onFieldTypeChanged(event) {
    const selectedFieldType = event.target.value

    if (selectedFieldType === 'hubspot_property_deal') {
      this.hubspotSectionTarget.disabled = false
    } else {
      this.hubspotSectionTarget.disabled = true
    }
  }
}
